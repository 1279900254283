export const myMenteeColumns = [{
    field: 'full_name',
    headerName: 'Name',
    flex: 1,
    id: 0,
},
{
    field: 'Professional_Bio',
    headerName: 'Professional',
    flex: 1,
    id: 1,
},
{
    field: 'phone_number',
    headerName: 'Contact',
    flex: 1,
    id: 2,
}, {
    field: 'email',
    headerName: 'Email',
    flex: 1,
    id: 1,
},
{
    field: 'location',
    headerName: 'Location',
    flex: 1,
    id: 2,
},
{
    field: 'attn_program',
    headerName: 'Attended Programs',
    flex: 1,
    id: 2,
}, {
    field: 'last_attend_program',
    headerName: 'Last Attend Program',
    flex: 1,
    id: 1,
},
];



export const discussionColumns = [
    {
        field: 'program_name',
        headerName: 'Program Name',
        flex: 1,
        id: 1,
    },
    {
        field: 'description',
        headerName: 'Description',
        flex: 1,
        id: 2,
    },
    {
        field: 'date',
        headerName: 'Date',
        flex: 1,
        id: 3,
    }, {
        field: 'task',
        headerName: 'Tasks',
        flex: 1,
        id: 4,
    },
    {
        field: 'users',
        headerName: 'Users',
        flex: 1,
        id: 5,
    },
    {
        field: 'comments',
        headerName: 'Comments',
        flex: 1,
        id: 6,
    },
    {
        field: 'last_update_by',
        headerName: 'Last update by',
        flex: 1,
        id: 7,
    }
];


export const memberRequestColumns = [
    {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        id: 1,
    },
    {
        field: 'reason',
        headerName: 'Reason',
        flex: 1,
        id: 2,
    },
    {
        field: 'location',
        headerName: 'Location',
        flex: 1,
        id: 3,
    }
];