import React, { useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Calendar } from 'primereact/calendar';

import CalendarIcon from '../../assets/images/calender_1x.png'
import SuccessTik from '../../assets/images/blue_tik1x.png';
import ClockIcon from '../../assets/icons/clock.svg'
import PlusIcon from '../../assets/images/plus_temp.png'
import CancelIcon from '../../assets/images/cancel-colour1x.png'
import Tooltip from '../../shared/Tooltip';
import { CreateMeetingFields } from '../../utils/formFields'
import { Button } from '../../shared';
import { getProgramMentees } from '../../services/userprograms';
import MuiModal from '../../shared/Modal';
import DataTable from '../../shared/DataGrid';
import { CalendarMentee } from '../../mock';
import { createCalendarEvent } from '../../services/scheduler';
import { calendarStatus } from '../../utils/constant';

export default function CreateMeeting() {
    const navigate = useNavigate()
    const { programMenteeList, loading: menteeLoading } = useSelector(state => state.userPrograms)
    const { loading: calendarLoading, status } = useSelector(state => state.events)
    const [internalLoading, setInternalLoading] = useState(false)
    const [createMeetingLoading, setCreateMeetingLoading] = useState(false)
    const [addMenteeModal, setMentalModal] = useState(false)
    const [datePopup, setDatepopup] = useState({ type: '', show: false, title: '' })
    const [customSelect, setCustomSelect] = useState({ type: '', date: '', repeat_time: '', repeat_type: '', never: false })
    const [dateFormat, setDateFormat] = useState({})
    const [dateError, setDateError] = useState({ date: '', repeat: '' })
    const [menteeAllList, setAllMenteeList] = useState([])
    const dispatch = useDispatch()
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
        getFieldState,
        getValues,
        setError,
        setValue
    } = useForm();


    const timeFormat = (utcTimestamp) => {
        console.log('utcTimestamp  --', utcTimestamp)
        let timeString = ''
        const t = utcTimestamp.toString().split(' ')
        if (t.length > 4) {
            let time = t[4].split(':')
            timeString = `${time[0]}:${time[1]}`
        }
        return timeString
    }


   
    const todayDate = () => {
        // console.log('customSelect', customSelect)

        const date = new Date(customSelect.date);
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // Months are zero-based
        const day = date.getDate();

        // Format the year as YY
        const shortYear = year.toString().padStart(2, '0');

        // Format month and day with leading zeros if necessary
        const formattedMonth = month.toString().padStart(2, '0');
        const formattedDay = day.toString().padStart(2, '0');

        // Combine into YY-mm-dd format
        return `${shortYear}-${formattedMonth}-${formattedDay}`;
    }

    const onSubmit = (data) => {
        console.log('Form Submit', data)

        let attendees = []
        data.attendees.forEach(attendee => {
            attendees.push(attendee.email)
        })

        let allGuest = []
        if (data.guests !== '') {
            let guestList = data.guests.split(',') || [];
            guestList.forEach(guest => {
                allGuest.push(guest)
            })
        }

        let apiData = {
            ...data,
            start: timeFormat(data.start),
            end: timeFormat(data.end),
            attendees: attendees,
            guests: allGuest,
            date: todayDate()
        }

        console.log('Apidata', apiData)
        dispatch(createCalendarEvent(apiData))

    }

    useEffect(() => {
        if (status === calendarStatus.create) {
            setCreateMeetingLoading(true)
        }
    }, [status])

    useEffect(() => {
        if (internalLoading) {
            setTimeout(() => {
                setInternalLoading(false)
                setCreateMeetingLoading(true)
            }, [2000])
        }
    }, [internalLoading])

    useEffect(() => {
        if (createMeetingLoading) {
            setTimeout(() => {
                setCreateMeetingLoading(false)
                navigate('/calendar')
            }, [3000])
        }
    }, [createMeetingLoading])

    const numbers = Array.from({ length: 10 }, (_, i) => i + 1);

    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    }

    const closeDatePopup = () => {
        setDatepopup({ type: '', show: false, title: '' })
    }

    const resetSelectedDate = () => {
        setCustomSelect({ type: '', date: '', repeat_time: '', repeat_type: '', never: false })
    }

    console.log('pppp', customSelect)

    const handleDate = (value) => {
        // resetSelectedDate()
        setCustomSelect({ type: '', date: new Date(), repeat_time: '', repeat_type: '', never: false })
        setDateError({ date: '', repeat: '' })
        if (value === 'do_not_repeat' || value === 'custom') {
            setDatepopup({ type: value, show: true, title: value === 'do_not_repeat' ? 'Does Not repeat' : 'Custom' })
        } else {
            closeDatePopup()
        }
    }

    const handleDateSelection = () => {
        if (datePopup.type === 'do_not_repeat' && customSelect.date === '') {
            setDateError({ date: 'This field is required' })
            return
        }

        if (datePopup.type === 'custom' && customSelect.date === '') {
            setDateError({ date: 'This field is required' })
            return
        }
        closeDatePopup()
    }

    const handleDateClick = () => {
        console.log('Click')
        document.querySelector('.p-datepicker')?.classList.add('calendar-date')
    }

    const handleCancelPopup = () => {
        closeDatePopup()
        setValue('date', '')
    }

    const footerAction = (key) => {
        setMentalModal(false)
    }

    const handleAddPopupData = (value) => {
        if (value.length) {
            setValue('attendees', value)
            setMentalModal(false)
            setAllMenteeList(value)
        }
    }

    const CustomFooterStatusComponent = (props) => {
        return (
            <div className='flex gap-6 justify-center items-center py-4'>
                <button onClick={() => setMentalModal(false)} className='py-3 px-6 w-[16%]'
                    style={{ border: '1px solid rgba(29, 91, 191, 1)', borderRadius: '3px', color: 'rgba(29, 91, 191, 1)' }}>Cancel</button>
                <button onClick={() => handleAddPopupData(props.selectedRows)}
                    className='text-white py-3 px-6 w-[16%]'
                    style={{ background: 'linear-gradient(93.13deg, #00AEBD -3.05%, #1D5BBF 93.49%)', borderRadius: '3px' }}>Add Mentees</button>
            </div>
        );
    }

    useEffect(() => {
        dispatch(getProgramMentees())

    }, [])

    const handleAction = () => { console.log('Handle'); setMentalModal(true) }

    return (
        <div className="dashboard-content px-8 mt-10">
            <div style={{ boxShadow: '4px 4px 25px 0px rgba(0, 0, 0, 0.05)', borderRadius: '10px' }}>
                <div className="title flex justify-between py-3 px-4 border-b-2 items-center">
                    <div className="flex gap-4">
                        <h4>Create New Meeting</h4>
                    </div>
                    <div className="flex gap-20 items-center">
                        <Tooltip title="Cancel">
                            <img className='cursor-pointer' onClick={() => navigate('/calendar')} src={CancelIcon} alt="CancelIcon" />
                        </Tooltip>
                    </div>
                </div>


                <Backdrop
                    sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={datePopup.show || menteeLoading | internalLoading || calendarLoading}
                >
                    {(menteeLoading || internalLoading || calendarLoading) && <CircularProgress color="inherit" />}
                    {
                        (datePopup.show && !menteeLoading) &&
                        <div className="popup-content w-1/4 bg-white flex flex-col gap-2">

                            <div className='py-5 w-full px-4'>
                                <div className="title flex justify-between py-3 px-4 border-b-2 items-center" style={{ color: 'rgba(29, 91, 191, 1)' }}>
                                    <div className="flex gap-4 font-semibold">
                                        <h4>{datePopup.title}</h4>
                                    </div>
                                    <div className="flex gap-20 items-center">
                                        <Tooltip title="Cancel">
                                            <img className='cursor-pointer' onClick={() => handleCancelPopup()} src={CancelIcon} alt="CancelIcon" />
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className='pt-4'>
                                    <div className='relative'>
                                        <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2">
                                            Date
                                        </label>
                                        <div className='relative'>
                                            <Calendar
                                                className='calendar-control input-bg'
                                                value={customSelect.date}
                                                onChange={(e) => {
                                                    setCustomSelect({ ...customSelect, type: datePopup.type, date: e.value })
                                                }}
                                                minDate={new Date()}
                                                onClick={handleDateClick}
                                                dateFormat="dd/mm/yy"

                                            />
                                            <img className='absolute top-5 right-2' src={CalendarIcon} alt="CalendarIcon" />
                                        </div>
                                        {dateError.date !== '' && (
                                            <p className="error" role="alert">
                                                {dateError.date}
                                            </p>
                                        )}
                                    </div>

                                    {
                                        datePopup.type === 'custom1' &&

                                        <>
                                            <div className='relative flex items-center mt-7 gap-2'>
                                                <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2">
                                                    Repeat every
                                                </label>
                                                <div className='ml-4'>
                                                    <select
                                                        className="w-[70px] border-none px-3 py-[0.32rem] leading-[2.15] input-bg 
                                                                        focus:border-none focus-visible:border-none focus-visible:outline-none text-[14px] h-[40px]"
                                                        style={{
                                                            color: "#232323",
                                                            borderRadius: '3px',
                                                            borderRight: '16px solid transparent',

                                                        }}
                                                        onChange={(e) => {
                                                            setCustomSelect({ ...customSelect, repeat_time: e.target.value })
                                                        }}
                                                    >
                                                        <option value="">Select</option>
                                                        {numbers.map(number => (
                                                            <option key={number} selected={number === 1} value={number}>
                                                                {number}
                                                            </option>
                                                        ))}

                                                    </select>
                                                </div>
                                                <div>
                                                    <select
                                                        className="w-[100px] border-none px-3 py-[0.32rem] leading-[2.15] input-bg 
                                                                        focus:border-none focus-visible:border-none focus-visible:outline-none text-[14px] h-[40px]"
                                                        style={{
                                                            color: "#232323",
                                                            borderRadius: '3px',
                                                            borderRight: '16px solid transparent',

                                                        }}
                                                        onChange={(e) => {
                                                            setCustomSelect({ ...customSelect, repeat_type: e.target.value })
                                                        }}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="day" selected>Day</option>
                                                        <option value="week">Week</option>
                                                        <option value="month">Month</option>
                                                    </select>
                                                </div>


                                            </div>
                                            <div className='mt-5'>
                                                <div className="flex items-center me-4">
                                                    <input type="radio" className="w-4 h-4 text-blue-600 bg-gray-100
                                                                                border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 
                                                                                dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700
                                                                                dark:border-gray-600"

                                                    />
                                                    <label className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Never</label>
                                                </div>
                                            </div>
                                        </>
                                    }


                                </div>
                            </div>
                            <div className='flex justify-center mb-4'>
                                <div className="flex gap-6 justify-center align-middle">
                                    <Button btnName='Cancel' btnCategory="secondary" onClick={() => handleCancelPopup()} />
                                    <Button btnType="button" btnCls="w-[110px]" btnName={'Done'} btnCategory="primary"
                                        onClick={handleDateSelection}
                                    />
                                </div>
                            </div>
                        </div>
                    }

                </Backdrop>

                <MuiModal modalOpen={createMeetingLoading} modalClose={() => setCreateMeetingLoading(false)} noheader>
                    <div className='px-5 py-1 flex justify-center items-center'>
                        <div className='flex justify-center items-center flex-col gap-5 py-10 px-20 mt-20 mb-20'
                            style={{ background: 'linear-gradient(101.69deg, #1D5BBF -94.42%, #00AEBD 107.97%)', borderRadius: '10px' }}>
                            <img src={SuccessTik} alt="SuccessTik" />
                            <p className='text-white text-[12px]'>Meeting created successfully to Mentees</p>
                        </div>

                    </div>
                </MuiModal>

                <MuiModal modalSize='lg' modalOpen={addMenteeModal} title="Select Mentees" modalClose={() => setMentalModal(false)}>
                    <DataTable rows={programMenteeList} columns={CalendarMentee} footerAction={footerAction} footerComponent={CustomFooterStatusComponent}
                        selectedAllRows={menteeAllList} />
                </MuiModal>

                <div className='px-8 py-4'>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex flex-wrap gap-4">
                            {
                                CreateMeetingFields.map((field, index) => {
                                    const dateField = field.type === 'time' ? register(field.name, field.inputRules) : undefined
                                    const dropdownimageField = field.type === 'dropdown' ? register(field.name, field.inputRules) : undefined

                                    return (
                                        <div className={`relative mb-6  ${getWindowDimensions().width <= 1536 && field.width === 'width-82' ? 'w-[81%]' : field.width}`} key={index}>
                                            <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor={field.label}>
                                                {field.label}
                                            </label>
                                            {
                                                field.type === 'input' ?
                                                    <div className='relative'>
                                                        <input {...register(field.name, field.inputRules)}
                                                            type={field.fieldtype}
                                                            className="w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none 
                                                            focus-visible:outline-none text-[14px] h-[60px]"
                                                            placeholder={field.placeholder}
                                                            style={{
                                                                color: "#232323",
                                                                borderRadius: '3px'
                                                            }}
                                                            aria-invalid={!!errors[field.name]}
                                                        />
                                                        {
                                                            field.icon && field.icon === 'add' &&
                                                            <Tooltip title={field.placeholder}>
                                                                <img className='absolute cursor-pointer top-4 right-4' onClick={() => handleAction(field.name)}
                                                                    src={PlusIcon} alt="PlusIcon" />
                                                            </Tooltip>

                                                        }

                                                        {errors[field.name] && (
                                                            <p className="error" role="alert">
                                                                {errors[field.name].message}
                                                            </p>
                                                        )}
                                                    </div>
                                                    :
                                                    field.type === 'popup-input' ?
                                                        <div className='relative'>
                                                            <div className='input-bg h-[60px] w-full mt-2 flex items-center 
                                                                                         text-[12px] gap-2 cursor-pointer px-6'
                                                                style={{ borderRadius: '3px' }}
                                                                onClick={() => handleAction(field.name)}
                                                            >

                                                                {
                                                                    menteeAllList && menteeAllList.slice(0, 6).map((popupfield, index) => {
                                                                        console.log('popupfield', popupfield)
                                                                        return (
                                                                            <>
                                                                                <p className='flex items-center gap-1'>
                                                                                    <p className='flex items-center px-3 py-3' style={{
                                                                                        background: 'rgba(223, 237, 255, 1)', borderRadius: '50%',

                                                                                    }}></p>
                                                                                    {


                                                                                        `${popupfield.first_name}`
                                                                                    }
                                                                                </p>
                                                                            </>
                                                                        )
                                                                    })
                                                                }

                                                                {
                                                                    menteeAllList && menteeAllList?.length > 6 &&

                                                                    <p className='flex items-center gap-1'>
                                                                        <p className='text-white flex items-center px-2 py-1' style={{
                                                                            background: 'rgb(29, 91, 191)', borderRadius: '50%',

                                                                        }}>{menteeAllList.length - 6}</p>
                                                                        Others</p>

                                                                }
                                                            </div>
                                                            <input {...register(field.name, field.inputRules)}
                                                                type={field.fieldtype}
                                                                className="w-full hidden border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none 
                                                                        focus-visible:outline-none text-[14px] h-[60px]"
                                                                placeholder={field.placeholder}
                                                                style={{
                                                                    color: "#232323",
                                                                    borderRadius: '3px'
                                                                }}
                                                                aria-invalid={!!errors[field.name]}
                                                            />
                                                            {
                                                                field.icon && field.icon === 'add' &&
                                                                <Tooltip title={field.placeholder}>
                                                                    <img className='absolute top-4 right-4 cursor-pointer'
                                                                        onClick={() => handleAction(field.name)} src={PlusIcon} alt="PlusIcon" />
                                                                </Tooltip>
                                                            }



                                                            {errors[field.name] && (
                                                                <p className="error" role="alert">
                                                                    {errors[field.name].message}
                                                                </p>
                                                            )}
                                                        </div>
                                                        :
                                                        field.type === 'dropdown' ?
                                                            <>
                                                                <select
                                                                    // {...register(field.name, field.inputRules)}
                                                                    {...dropdownimageField}
                                                                    className="w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg 
                                                                        focus:border-none focus-visible:border-none focus-visible:outline-none text-[14px] h-[60px]"
                                                                    placeholder={field.placeholder}
                                                                    style={{
                                                                        color: "#232323",
                                                                        borderRadius: '3px',
                                                                        borderRight: '16px solid transparent',
                                                                        marginTop: field.label === '' ? '16px' : ''
                                                                    }}
                                                                    onChange={(e) => {
                                                                        dropdownimageField.onChange(e)
                                                                        if(field.name === 'date_category') handleDate(e.target.value);
                                                                    }}
                                                                >
                                                                    <option value="">Select</option>
                                                                    {
                                                                        field.options.map((option, index) => <option value={option.key || option.id} key={index}> {option.value || option.name} </option>)
                                                                    }
                                                                </select>
                                                                {errors[field.name] && (
                                                                    <p className="error" role="alert">
                                                                        {errors[field.name].message}
                                                                    </p>
                                                                )}
                                                            </>
                                                            :
                                                            field.type === 'time' ?

                                                                <div className='relative'>
                                                                    <Calendar
                                                                        className='calendar-control input-bg'
                                                                        {...dateField}
                                                                        value={dateFormat[field.name]}
                                                                        onChange={(e) => {
                                                                            console.log('dateField123', e.value)
                                                                            dateField.onChange(e)
                                                                            setDateFormat({ ...dateFormat, [field.name]: e.value })
                                                                        }}
                                                                        timeOnly
                                                                        time
                                                                    />
                                                                    <img className='absolute top-5 right-2' src={ClockIcon} alt="ClockIcon" />

                                                                    {errors[field.name] && (
                                                                        <p className="error" role="alert">
                                                                            {errors[field.name].message}
                                                                        </p>
                                                                    )}
                                                                </div>
                                                                :
                                                                null
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="flex gap-6 justify-center align-middle">
                            <Button btnName='Cancel' btnCls="w-[170px]" btnStyle={{ border: '1px solid rgba(29, 91, 191, 1)', color: 'rgba(29, 91, 191, 1)' }} btnCategory="secondary" onClick={() => navigate('/calendar')} />
                            <Button btnName='Draft' btnCls="w-[170px]" btnStyle={{ background: 'rgba(217, 228, 242, 1)', color: 'rgba(29, 91, 191, 1)', border: 'none' }} btnCategory="secondary" onClick={() => console.log('draft')} />
                            <Button btnType="submit" btnCls="w-[170px]" btnName={'Create Meeting'} btnCategory="primary" />
                        </div>
                    </form>

                </div>

            </div>
        </div>
    )
}
